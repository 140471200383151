<template>
  <div
    ref="rootElement"
    :class="['table-row', isEventSuspended && 'suspended', isBetPartRow && 'bet-part', isDisabled && 'disabled', isHidden && 'hidden']"
    :style="rowStyle"
    tabindex="0"
    @click.stop="emitRowClicked(row)"
    @keydown.stop="emitRowClicked(row, $event)"
    @mouseover="onMouseEnter"
    @mouseleave="onMouseLeave"
    @contextmenu.prevent="emitContextMenu"
    :title="rowTitle"
  >
    <div
      v-if="selectable && !hasDpsTraderRole"
      class="table-cell table-cell--selected"
    >
      <Checkbox
        :model-value="isSelected"
        @update:model-value="(value) => updateRowSelection(row, value)"
        :disabled="!row.selectable || isDisabled"
      />
    </div>
    <div
      v-for="column in visibleColumns"
      :key="column.key"
      :class="[
        'table-cell',
        `table-cell--${column.key}`,
      ]"
      :style="getCellStyle(column.key)"
    >
      <slot
        name="cell"
        :column="column"
      >
        {{ row[column.key] }}
      </slot>
    </div>
  </div>
</template>

<script>
import {
  ref, computed, readonly, inject, provide,
} from 'vue';
import Checkbox from './Checkbox';

export default {
  components: {
    Checkbox,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    visibleColumns: {
      type: Array,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    hasDpsTraderRole: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'row-clicked': {
      type: Object,
    },
    'row-selected': {
      type: Object,
    },
    'row-unselected': {
      type: Object,
    },
    'row-hovered': {
      type: Boolean,
    },
    'row-context-menu': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);
    const selectable = inject('selectable', false);
    const isEventSuspended = computed(() => props.row.isSuspended);
    const isDisabled = computed(() => props.row.limbo);
    const isHidden = computed(() => props.row.isHidden);
    const isBetPartRow = computed(() => props.row.isBetPart);
    const rowStyle = computed(() => {
      if (!props.row.highlights || !props.row.highlights.row) {
        if (props.row.highlightedRow) {
          return {
            'font-weight': 600,
          };
        }
        return {};
      }
      const { row: highlight } = props.row.highlights;
      if (props.row.highlightedRow) {
        return {
          'background-color': highlight.backgroundColor,
          color: highlight.color,
          'font-weight': 600,
        };
      }
      return {
        'background-color': highlight.backgroundColor,
        color: highlight.color,
      };
    });
    const getCellStyle = (key) => {
      if (!props.row.highlights) return {};
      const { [key]: highlight } = props.row.highlights;
      if (!highlight) return {};
      return {
        'background-color': highlight.backgroundColor,
        color: highlight.color,
      };
    };
    const rowTitle = computed(() => {
      if (isDisabled.value) {
        return 'Disabled event';
      }
      if (isEventSuspended.value) {
        return 'Suspended event';
      }
      if (isHidden.value) {
        return 'Hidden event';
      }
      return '';
    });
    const emitRowClicked = (row, event) => {
      if (event && event?.keyCode !== 13) return;
      emit('row-clicked', row);
    };
    const updateRowSelection = (row, value) => {
      emit(`${value ? 'row-selected' : 'row-unselected'}`, row);
    };

    const isHovered = ref(false);
    const onMouseEnter = () => {
      isHovered.value = true;
    };
    const onMouseLeave = () => {
      isHovered.value = false;
    };
    provide('isHovered', readonly(isHovered));

    const emitContextMenu = (event) => {
      emit('row-context-menu', {
        top: event.y,
        left: event.x,
        row: props.row,
      });
    };

    return {
      rootElement,
      selectable,
      isEventSuspended,
      isDisabled,
      isBetPartRow,
      rowStyle,
      getCellStyle,
      emitRowClicked,
      updateRowSelection,
      onMouseEnter,
      onMouseLeave,
      emitContextMenu,
      isHidden,
      rowTitle,
    };
  },
};
</script>

<style lang="scss">
  .table-row {
    .table-cell:first-child {
      padding-left: 24px;
    }
    .table-cell {
      border-bottom: $tableRowBorder;
    }
  }
  .table-row.suspended .table-cell{
    background-color: $error50 !important;

    &:hover {
      background-color: $error50 !important;
    }

    .checkbox::after {
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      background-color: $error500;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .table-row.hidden .table-cell{
    background-color: $gray400 !important;

    &:hover {
      background-color: $gray400 !important;
    }

    .checkbox::after {
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      background-color: $gray700;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .table-row.disabled .table-cell{
    background-color: $gray300 !important;
    color: $gray700;
    &:hover {
      background-color: $gray300 !important;
    }

    .checkbox::after {
      background-color: $gray700;
    }
  }
  .table-row.bet-part .table-cell{
    background-color: $gray400;
  }
  .table-row .table-cell {
    background-color: inherit;
    color: inherit;
  }
</style>
