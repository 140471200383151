<template>
  <div class="empty-state">
    <Icon :name="'empty-state'" />
    <p class="title">
      {{ message ? message : 'No available events.' }}
    </p>
    <p
      v-if="subtitle"
      class="subtitle"
    >
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    Icon,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  setup() {},
};
</script>

<style lang="scss">
.empty-state {
  display: flex;
  flex-direction: column;
  text-align: center;

  .icon {
    width: auto;
    height: auto;
  }

  .title {
    font-size: 16px;
    line-height: 19px;
    margin: 33px 0 8px 0;
  }
}
</style>
